<template>
  <footer class="home-footer mt-5">
    <r-container>
      <r-card>
        <div class="d-flex br-lg pa-3 color-white v-center h-space-between">
          <div class="community-text">
            <h3 class="title-1 font-weight-bold">
              {{ $t("join_community") }}
            </h3>
            <div>
              {{ $t("join_community_desc") }}
            </div>
          </div>
          <r-btn
              class="ms-2 color-black color-white-text br-md"
              :to="{ name: 'dashboard' }"
          >{{ $t("join") }}
          </r-btn
          >
        </div>
      </r-card>
      <r-row class="position-relative mt-4 br-lg footer-border "
             :class="{'flex-row-reverse':$r.breakpoint.smAndUp}">

        <r-col class="col-12">
          <r-container>
            <r-row>
              <r-col class="col-6 md-4">
                <div class="title-2 font-weight-bold mb-3">{{ $t("pages") }}</div>
                <r-divider class="me-20 mb-2 color-warning"></r-divider>
                <div class="title-3 mb-3">
                  <router-link to="/">{{ $t("Home") }}</router-link>
                </div>
                <div class="title-3 mb-3">
                  <router-link :to="{name: 'blogs'}">{{ $t("blogs") }}</router-link>
                </div>
                <div class="title-3 mb-3">
                  <router-link :to="{name: 'careers'}">{{ $t("careers") }}</router-link>
                </div>
                <div class="title-3 mb-3">
                  <router-link :to="{name: 'cat_list'}">{{ $t("projects") }}</router-link>
                </div>
                <div class="title-3 mb-3">
                  <router-link :to="{name: 'contact_us'}">{{ $t("contact_us") }}</router-link>
                </div>
              </r-col>
              <r-col class="col-6 md-4">
                <div class="title-2 font-weight-bold mb-3">{{ $t("product") }}</div>
                <r-divider class="me-20 mb-2 color-warning"></r-divider>
                <div class="title-3 mb-2">{{ $t("ecommerce") }}</div>
                <div class="title-3 mb-2">{{ $t("company") }}</div>
                <div class="title-3 mb-2">{{ $t("doctor") }}</div>
                <div class="title-3 mb-2">{{ $t("blog") }}</div>
              </r-col>
              <r-col class="col-6 md-4">
                <div class="title-2 font-weight-bold mb-3">
                  {{ $t("help") }}
                </div>
                <r-divider class="me-sm-24 me-5 mb-2 color-warning"></r-divider>
                <div class="title-3 mb-2">
                  <router-link :to="{name: 'dashboard'}">{{ $t("build_ai") }}</router-link>
                </div>
                 <div class="title-3 mb-2">{{ "bot_help" }}</div>
                <div class="title-3 mb-2">{{ "domain_help" }}</div>
                <div class="title-3 mb-2">{{ "zarinpal_account" }}</div>
              </r-col>
            </r-row>
          </r-container>
        </r-col>
        <r-col class="col-12"
        >
          <r-img
              src="/pwa/logo?t=m&w=180"
              width="180"
              height="60"
              alt="codenus"
          ></r-img>
          <div>
            <r-btn
                v-for="(item, i) in social_media"
                :key="i"
                icon
                outlined
                class="ma-2 br-lg btn-icon"
                v-title="i"
                :href="item"
                target="_blank"
            >
              <r-img
                  :src="`/storage/logos/${i}.svg`"
                  :alt="i"
                  width="25"
                  height="25"
                  is-svg
              />
            </r-btn>
          </div>
          <install-app class="mt-5 br-md"></install-app>
        </r-col>
      </r-row>
    </r-container>

    <r-container full-width class="color-black text-center title-3">
      <r-row class="h-center no-gutters">
        <r-col class="col-auto color-white-text">
          {{ $t("footer_copyright_msg") }}
        </r-col>
      </r-row>
    </r-container>
  </footer>
</template>

<script>
import InstallApp from "./installApp";

export default {
  name: "homeFooter",
  components: {InstallApp},
  data() {
    return {
      social_media: {},
    };
  },
  created() {
    this.get();
  },
  methods: {
    go(hash) {
      if (this.$route.name !== hash.to.name) {
        this.$router.push(hash.to);
        setTimeout(() => {
          const el = document.getElementById(hash.id);
          if (el !== null) {
            el.scrollIntoView();
          }
        }, 1000);
      } else {
        setTimeout(() => {
          const el = document.getElementById(hash.id);
          if (el !== null) {
            el.scrollIntoView();
          }
        }, 100);
      }
    },
    get() {
      this.$axios.get("home/settings/multi/social_media").then(({data}) => {
        for (let i = 0; i < data.length; i++) {
          this[data[i]["name"]] = data[i]["value"];
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";

.home-footer {
  .btm-line {
    width: 150px;
    height: 4px;
  }

  .btn-icon {
    opacity: 0.5;
      color: #000;
    &:hover {
      background-color: #000;
      color: white;
      opacity: 1;
    }
  }
}

.footer-border {
  border: 2px solid var(--color-one);
}

.bot-footer {
  position: relative;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.7);
  transform: rotateY(5deg);
}

@include media-breakpoint-up("sm") {
  .bot-footer {
    position: absolute;
    top: 2%;
  }
}

@include media-breakpoint-up("md") {
  .bot-footer {
    position: absolute;
    top: 4%;
  }
}

.bot-footer::before {
  content: "";
  position: absolute;
  top: 0%;
  right: 0%;
  width: 0px;
  height: 0px;
  border-bottom: 70px solid #eee;
  border-right: 70px solid transparent;
  box-shadow: -7px 7px 7px rgba(0, 0, 0, 0.3);
}

.bot-footer::after {
  content: "";
  position: absolute;
  top: 0%;
  right: 0%;
  width: 0px;
  height: 0px;
  border-top: 69px solid #272822;
  border-left: 69px solid transparent;
}
</style>
