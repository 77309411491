<template>
  <div class="template-home color-white">
    <header class="header z-important">

      <r-container class="menu v-center d-flex h-space-between"
      >
        <r-btn icon text v-if="$r.breakpoint.mdAndDown" @click="open = !open">
          <r-icon v-html="open ? $r.icons.close : $r.icons.menu"></r-icon>
        </r-btn>
        <div v-else>
          <router-link
              v-for="(item, i) in baseMenu"
              :key="i"
              class="title-2 d-inline-flex pa-2 me-2"
              :to="item.to"
              @click="open = false"
          >{{ item.title }}
          </router-link
          >
        </div>

        <div class="d-flex v-center"
             :class="{'h-space-between': !$r.breakpoint.smAndDown,
       'flex-row-reverse': $r.breakpoint.smAndDown}"
        >
          <lang-select></lang-select>
          <r-btn class="mx-3" text :to="{ name: 'dashboard' }">{{ $t("dashboard") }}</r-btn>
          <r-img
              src="/pwa/logo?t=m&w=180"
              :width="$r.breakpoint.smAndDown ? 100 : 180"
              :height="$r.breakpoint.mdAndDown ? 40 : 60"
              alt="codenus"
          ></r-img>
        </div>
      </r-container>
      <div
          v-if="$r.breakpoint.mdAndDown"
          class="menu-mob"
          :class="{ 'menu-open': open }"
      >
        <div v-for="(item, i) in baseMenu" :key="i" class="title pa-2">
          <router-link :to="item.to" @click="open = false">{{
              item.title
            }}
          </router-link>
        </div>
      </div>
    </header>
    <r-content>
      <router-view v-slot="{ Component }">
        <transition name="slide-end" mode="out-in">
          <keep-alive :exclude="['singleBlog']">
            <component :is="Component"/>
          </keep-alive>
        </transition>
      </router-view>
    </r-content>
    <home-footer></home-footer>
  </div>
</template>

<script>
import HomeFooter from "../components/homeFooter";
import LangSelect from "@/components/langSelect";

export default {
  name: "home",
  components: {LangSelect, HomeFooter},
  data() {
    return {
      open: false,
      baseMenu: [
        {title: this.$t("home"), to: {name: "base"}},
        {
          title: this.$t("build_ai"),
          to: {name: "dashboard"},
        },
        {
          title: this.$t("learn"),
          to: {name: "blogs"},
        },
        {
          title: this.$t("careers"),
          to: {name: "careers"},
        },
        {
          title: this.$t("contact_us"),
          to: {name: "contact_us"},
        },
      ],
    };
  },
  watch: {
    open: function (n) {
      if (n) {
        document.documentElement.style.overflow = "hidden";
        window.scrollTo(0, 0);
      } else {
        document.documentElement.style.overflow = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/_include";

.template-home {
  .color-card,.r-input-container .input-control {
    background-color: #f4f2f2 !important;
  }

  .r-content {
    min-height: 80vh;
  }

  .router-link-exact-active:not(.breadcrumb-item-disabled) {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    filter: brightness(150%);
    font-weight: bold;
    transition: $primary-transition;
    @include rtl() {
      background-image: linear-gradient(
              114deg,
              var(--color-three),
              var(--color-one)
      );
    }
    @include ltr() {
      background-image: linear-gradient(
              114deg,
              var(--color-one),
              var(--color-three)
      );
    }
  }

  .header {
    position: sticky;
    top: 0;
    width: 100%;
    background: #ffffff;

    a {
      font-weight: bold !important;
    }

    .menu {
      position: relative;
      z-index: 1;
    }

    .menu-mob {
      position: absolute;
      left: 0;
      width: 100vw;
      height: 0;
      z-index: 3;
      transition: 0.5s height ease-in-out;
      overflow: hidden;
      background: #ffffff;

      &.menu-open {
        height: calc(100vh - 80px);
        overflow: auto;
      }
    }
  }
}
</style>
