<template>
  <panel :menu="menu"></panel>
</template>

<script>
import Panel from "./panel";

export default {
  name: "user",
  components: { Panel },
  data() {
    return {
      menu: [
        {
          name: "dashboard",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M21 14V4H3v10h18m0-12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-7l2 3v1H8v-1l2-3H3a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2h18M4 5h11v5H4V5m12 0h4v2h-4V5m4 3v5h-4V8h4M4 11h5v2H4v-2m6 0h5v2h-5v-2Z"/></svg>',
          to: { name: "dashboard" },
        },
        {
          name: "orders",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M18 2a.75.75 0 0 1 .475.169l.075.07l3.273 3.53l.03.038c.102.136.148.29.148.44l-.002 1.92c0 .994-.378 1.9-1 2.58V21.25a.75.75 0 0 1-.648.743l-.102.007H3.752a.75.75 0 0 1-.744-.648l-.006-.102L3 10.748a3.818 3.818 0 0 1-.993-2.353L2 8.167V6.29a.728.728 0 0 1 .096-.408l.065-.095l.04-.046L5.45 2.24a.75.75 0 0 1 .446-.233L6 2h12Zm-2.918 8.442l-.012.018A3.827 3.827 0 0 1 12 12a3.827 3.827 0 0 1-3.084-1.556A3.825 3.825 0 0 1 5.834 12c-.469 0-.918-.084-1.334-.238l.001 8.738H6v-6.748a.75.75 0 0 1 .649-.743L6.75 13h4.496a.75.75 0 0 1 .743.648l.007.102v6.748h7.502v-8.737a3.827 3.827 0 0 1-4.416-1.32Zm-4.587 4.059H7.5v5.998h2.995v-5.998Zm6.76-1.5a.75.75 0 0 1 .743.648l.007.102v3.502a.75.75 0 0 1-.648.743l-.102.007h-3.501a.75.75 0 0 1-.743-.648l-.007-.102v-3.502a.75.75 0 0 1 .648-.743l.102-.007h3.501Zm-.75 1.5h-2.002v2.002h2.001v-2.002Zm-8.34-7.499H3.501v1.165l.007.17l.028.232l.033.156l.05.172l.054.148l.04.094c.031.068.066.134.103.198l.103.162l.054.074l.129.156l.142.144l.096.085l.042.034c.315.25.695.422 1.112.483l.18.019l.16.005a2.332 2.332 0 0 0 2.327-2.173l.006-.16V7.003Zm6.165 0H9.666v1.165c0 1.18.878 2.157 2.017 2.311l.156.016l.16.005a2.332 2.332 0 0 0 2.327-2.173l.006-.16V7.003Zm6.167 0h-4.665l.001 1.165c0 1.18.878 2.157 2.016 2.311l.157.016l.16.005c.564 0 1.081-.2 1.485-.534l.09-.078l.116-.113l.146-.17c.054-.069.104-.14.15-.216l.104-.186l.063-.138l.058-.155l.03-.096l.038-.152l.028-.157l.018-.167l.006-.17V7.002ZM9.062 3.499H6.327L4.47 5.502h3.977l.616-2.003Zm4.307 0h-2.738l-.616 2.003h3.97l-.616-2.003Zm4.303 0h-2.734l.616 2.003h3.976l-1.858-2.003Z"/></svg>',
          to: { name: "orders" },
        },
        {
          name: "tickets",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 14H5.2L4 17.2V4h16v12m-3-5h-2V9h2m-4 2h-2V9h2m-4 2H7V9h2"/></svg>',
          to: { name: "tickets" },
        },
        {
          name: "profile",
          icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          to: { name: "profile" },
        },
        {
          name: "settings",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="24" height="24" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12.013 2.25c.734.008 1.465.093 2.181.253a.75.75 0 0 1 .582.649l.17 1.527a1.384 1.384 0 0 0 1.928 1.116l1.4-.615a.75.75 0 0 1 .85.174a9.793 9.793 0 0 1 2.204 3.792a.75.75 0 0 1-.271.825l-1.242.916a1.38 1.38 0 0 0 .001 2.226l1.243.915a.75.75 0 0 1 .271.826a9.798 9.798 0 0 1-2.203 3.792a.75.75 0 0 1-.849.175l-1.406-.617a1.38 1.38 0 0 0-1.927 1.114l-.169 1.526a.75.75 0 0 1-.572.647a9.518 9.518 0 0 1-4.405 0a.75.75 0 0 1-.572-.647l-.17-1.524a1.382 1.382 0 0 0-1.924-1.11l-1.407.616a.75.75 0 0 1-.849-.175a9.798 9.798 0 0 1-2.203-3.796a.75.75 0 0 1 .271-.826l1.244-.916a1.38 1.38 0 0 0 0-2.226l-1.243-.914a.75.75 0 0 1-.272-.826a9.793 9.793 0 0 1 2.205-3.792a.75.75 0 0 1 .849-.174l1.4.615a1.387 1.387 0 0 0 1.93-1.118l.17-1.526a.75.75 0 0 1 .583-.65c.718-.159 1.45-.243 2.202-.252Zm0 1.5a9.135 9.135 0 0 0-1.355.117l-.109.977A2.886 2.886 0 0 1 6.525 7.17l-.898-.394a8.293 8.293 0 0 0-1.348 2.317l.798.587a2.881 2.881 0 0 1 0 4.643l-.798.588c.32.842.775 1.626 1.347 2.322l.906-.397a2.882 2.882 0 0 1 4.017 2.318l.108.984c.89.15 1.799.15 2.689 0l.108-.984a2.88 2.88 0 0 1 4.02-2.322l.904.396a8.299 8.299 0 0 0 1.347-2.318l-.798-.588a2.88 2.88 0 0 1 0-4.643l.796-.587a8.293 8.293 0 0 0-1.348-2.317l-.896.393a2.884 2.884 0 0 1-4.023-2.324l-.11-.976a8.99 8.99 0 0 0-1.333-.117ZM12 8.25a3.75 3.75 0 1 1 0 7.5a3.75 3.75 0 0 1 0-7.5Zm0 1.5a2.25 2.25 0 1 0 0 4.5a2.25 2.25 0 0 0 0-4.5Z"/></svg>',
          to: { name: "userSettings" },
        },
      ],
    };
  },
};
</script>
