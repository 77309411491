import { createRouter, createWebHistory } from "vue-router";

import IndexLayout from "../layouts/home";
import UserLayout from "../layouts/user";
import { auth, profile_complete } from "./guards";

const law = () => import("../views/index/user/law");
/* {{place new import}} */
const ticket = () => import("app/extension/home/ticket/view/index_user.vue");
const pages = () => import("app/extension/home/page/view/page.vue");
const singleBlog = () => import("app/extension/home/blog/view/singleBlog.vue");
const blogs = () => import("app/extension/home/blog/view/blogs.vue");
const userSetting = () => import("app/extension/renus/user/view/setting.vue");
const profile = () => import("app/extension/renus/user/view/profile.vue");
const login = () => import("app/extension/renus/user/view/login.vue");
const base = () => import("../views/index/home");
const notFound = () => import("../views/index/notFound");
const careers = () => import("../views/index/careers");
const cat_list = () => import("../views/index/cat_list");
const contact_us = () => import("../views/index/contact_us");
const paymentVerify = () => import("../views/index/paymentVerify");

/* user panel*/
const dashboard = () => import("../views/index/user/index");
const orders = () => import("../views/index/user/order");

const routes = [
  { path: "/home/login/:token?", name: "login", component: login },
  {
    path: "/user",
    component: UserLayout,
    beforeEnter: (to, from, next) => {
      auth(to, from, next);
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: dashboard,
        beforeEnter: (to, from, next) => {
          profile_complete(to, from, next);
        },
      },
      {
        path: "orders",
        name: "orders",
        component: orders,
        beforeEnter: (to, from, next) => {
          profile_complete(to, from, next);
        },
      },
      /* {{place new Route user}} */

      {
        path: "user-settings",
        name: "userSettings",
        component: userSetting,
        beforeEnter: (to, from, next) => {
          profile_complete(to, from, next);
        },
      },
      { path: "profile", name: "profile", component: profile },
      {
        path: "tickets",
        name: "tickets",
        component: ticket,
        beforeEnter: (to, from, next) => {
          profile_complete(to, from, next);
        },
      },
    ],
  },
  {
    path: "/",
    component: IndexLayout,
    children: [
      { path: "", name: "base", component: base },
      {
        path: "/home/payment/:status/:refId",
        name: "paymentVerify",
        component: paymentVerify,
        props: true,
      },
      {
        path: "/home/careers",
        name: "careers",
        component: careers,
      },{
        path: "/home/cat-list",
        name: "cat_list",
        component:cat_list,
      },
      /*{
        path: "/home/samples",
        name: "samples",
        component: samples,
        props: true,
      },*/

      {
        path: "/home/laws",
        name: "laws",
        component: law,
      },
      /* {{place new Route home}} */
      {
        path: "/home/pages/:slag",
        name: "pages",
        component: pages,
        props: true,
      },
      {
        path: "/home/singleBlog/:blog_id/:title",
        name: "singleBlog",
        component: singleBlog,
        props: true,
      },
      {
        path: "/home/blogs/:genum?/:tag?",
        name: "blogs",
        component: blogs,
        props: true,
      },

      {
        path: "/home/contact-us",
        name: "contact_us",
        component: contact_us,
      },
      { path: "/:pathMatch(.*)*", name: "not_found", component: notFound },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production"
      ? process.env.API_production_URL
      : process.env.API_URL
  ),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition) {
        setTimeout(() => {
          resolve({ left: 0, top: savedPosition["top"] });
        }, 500);
      } else {
        resolve({ left: 0, top: 0 });
      }
    });
  },
});

export default router;
